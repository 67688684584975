<template>
    <h1>Books by Robbie Mahair</h1>
    <span><a href="https://www.amazon.com/Robbie-Mahair/e/B07FJMSNLM">Robbie Mahair on Amazon</a></span>

    <div class="row section">
        <div class="col-md-4 col-6 imageDiv">
            <a href="https://www.amazon.com/gp/product/B07FC687QN/"><img src="@/assets/images/books/OrcRoadTrip_Shadow.png" alt="orc road trip book" class="img-fluid image" /></a>
        </div>
        <div class="col-md-8 col-6 descriptionDiv">
            <div>
                <h2>Orc Road Trip</h2>
                <hr />
                <p class="highlightText">A fun and funny ride with your favorite butt-kicking orc, Thrug!</p>
                <p>
                    Tom Matherson is a citizen of the great United States of America, a cultural mosaic where all are equal, but some, like elves,
                    are more equal than others, and others, like orcs, aren’t equal at all. When Tom planned a road trip with his girlfriend, he
                    didn’t intend to help a lonely dwarf with her quest for Mr. Right, and he certainly didn’t intend to chauffeur an orc, thwart
                    terrorist plots, or make any attempts to save the President. But sometimes in life you have to roll with the punches, especially
                    when the one throwing them is three hundred pounds of green muscle.
                </p>
            </div>
            <div class="buyItDiv">
                <a class="buyItButton" href="https://www.amazon.com/gp/product/B07FC687QN/">Shop now at Amazon.com</a>
            </div>
        </div>
    </div>
    <div class="row section">
        <div class="col-md-4 col-6 imageDiv">
            <a href="https://www.amazon.com/gp/product/B07S24L897/"><img src="@/assets/images/books/StoriesNotFromTheBible_Shadow.png" alt="stories not from the bible book" class="img-fluid image" /></a>
        </div>
        <div class="col-md-8 col-6 descriptionDiv">
            <div>
                <h2>Stories Not from the Bible</h2>
                <hr />
                <p class="highlightText">See the Bible as you’ve never seen it before!</p>
                <p>
                    Take a journey into the minds and hearts of characters from the Bible, both great and small, through these ten short stories.
                    How did Leah feel being forced to share a husband who didn't love her with her own sister? What did the criminal on the cross
                    think as he watched Jesus, who had just promised him paradise, give up his spirit and go limp next to him? Why did the angel
                    who rescued Peter from prison strike him on the side rather than gently wake him up?
                </p>
                <p>
                    Stories Not from the Bible explores the answers to these questions and others with fictional accounts that are anchored in the
                    truth of the Bible, including hundreds of references so you can explore the relevant passages for yourself. The answer to each
                    question reveals a little more about the God who loves us beyond what we can comprehend.
                </p>
            </div>
            <div class="buyItDiv">
                <a class="buyItButton" href="https://www.amazon.com/gp/product/B07S24L897/">Shop now at Amazon.com</a>
            </div>
        </div>
    </div>
    <div class="row section">
        <div class="col-md-4 col-6 imageDiv">
            <a href="https://www.amazon.com/gp/product/1949495051/"><img src="@/assets/images/books/ATaleOfTwoDonkeys_Shadow.png" alt="a tale of two donkeys book" class="img-fluid image" /></a>
        </div>
        <div class="col-md-8 col-6 descriptionDiv">
            <div>
                <h2>A Tale of Two Donkeys</h2>
                <hr />
                <p class="highlightText">A beautifully illustrated Bible-based bedtime story for kids!</p>
                <p>
                    It's finally time for Donny Donkey's first day of work on the farm! He's got a big job to do, and big emotions to deal with, but fortunately Daddy Donkey knows two stories that will help him be both brave and humble.
                </p>
                <p>
                    The Bible-inspired stories and fun illustrations in this book are perfect for both playtime and bedtime!
                </p>
            </div>
            <div class="buyItDiv">
                <a class="buyItButton" href="https://www.amazon.com/gp/product/1949495051/">Shop now at Amazon.com</a>
            </div>
        </div>
    </div>
    <div class="row section">
        <div class="col-md-4 col-6 imageDiv">
            <a href="https://www.amazon.com/gp/product/194949506X/"><img src="@/assets/images/books/ReadMe_Shadow.png" alt="read me book" class="img-fluid image" /></a>
        </div>
        <div class="col-md-8 col-6 descriptionDiv">
            <div>
                <h2>Psst! Read Me!</h2>
                <hr />
                <p class="highlightText">The book is so excited you've come to read it and can't wait to tell you a story!</p>
                <p>
                  “Oh! Hey! You're reading my description!
                </p>
                <p>
                  “I guess that means I should tell you about myself, huh? Well, as you can see, I'm a book. And I have an awesome story to tell you about Essie the ninja and how she fought an evil army of exploding ninjas led by a ruthless tiger who used to be a doctor, but, well, that's a long story; you'll just have to read me to find out more.
                </p>
                <p>
                  “I can't wait for you to read me! Seriously! Please! Just sitting here not being read is sooo boring!”
                </p>
            </div>
            <div class="buyItDiv">
                <a class="buyItButton" href="https://www.amazon.com/gp/product/194949506X/">Shop now at Amazon.com</a>
            </div>
        </div>
    </div>
</template>