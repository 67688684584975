<template>
  <div class="col-xl-6 col-lg-6 col-md-12 col-12">
    <div class="imageDiv">
      <a :href="shirtUrl">
        <picture>
            <source :srcset="require(`@/assets/images/shirts/${imageFileName}.webp`)" type="image/webp">
            <source :srcset="require(`@/assets/images/shirts/${imageFileName}.jpg`)" type="image/jpeg">
            <img :src="require(`@/assets/images/shirts/${imageFileName}.jpg`)" :alt="altText" class="img-fluid image" loading="lazy">
        </picture>
      </a>
    </div>
    <div class="textDiv">
      <span class="titleSpan"><a :href="shirtUrl">{{shirtName}}</a></span>
      <span class="colorSpan">{{colorAndSize}}</span>
      <span class="priceSpan">$22.48</span>
    </div>
  </div>
</template>

<script>
	export default {
		name: 'OneShirt',
		props: {
			imageFileName: {
				type: String,
				required: true,
			},
			altText: {
				type: String,
				required: true,
			},
			shirtName: {
				type: String,
				required: true,
			},
			shirtUrl: {
				type: String,
				required: true,
			},
			colorAndSize: {
				type: String,
				required: true,
			},
		},
	}
</script>

<style lang="scss">

.textDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.titleSpan{
  font-weight: bold;
}

.colorSpan {
  color: #666666;
}

.priceSpan {
  color: #666666;
}

</style>