<template>
  <h1>Autosaved T-Shirts</h1>
  <div class="row section">
    <div class="col-12">
      All profits from t-shirt sales until March 31, 2024 will go towards funding my son's school trip to Germany.
    </div>
    <one-shirt
      imageFileName="joe_by_den_black_text"
      altText="Joe by Den, Black Text"
      shirtName="Joe by Den Shirt (Black Text)"
      shirtUrl="https://autosaved-store.printify.me/product/3078442/joe-by-den-t-shirt-black-text"
      colorAndSize="5 colors, 8 sizes"
    />
    <one-shirt
      imageFileName="joe_by_den_white_text"
      altText="Joe by Den, White Text"
      shirtName="Joe by Den Shirt (White Text)"
      shirtUrl="https://autosaved-store.printify.me/product/3078439/joe-by-den-t-shirt-white-text"
      colorAndSize="8 colors, 8 sizes"
    />
    <one-shirt
      imageFileName="work_shirt_black_text"
      altText="Work Shirt, Black Text"
      shirtName="Work Shirt (Black Text)"
      shirtUrl="https://autosaved-store.printify.me/product/3078449/this-is-my-work-shirt-t-shirt-black-text"
      colorAndSize="5 colors, 8 sizes"
    />
    <one-shirt
      imageFileName="work_shirt_white_text"
      altText="Work Shirt, White Text"
      shirtName="Work Shirt (White Text)"
      shirtUrl="https://autosaved-store.printify.me/product/3078446/this-is-my-work-shirt-t-shirt-white-text"
      colorAndSize="8 colors, 8 sizes"
    />
    <one-shirt
      imageFileName="live_forever_black_text"
      altText="I'm Gonna Live Forever, Black Text"
      shirtName="I'm Gonna Live Forever (Black Text)"
      shirtUrl="https://autosaved-store.printify.me/product/3078435/im-gonna-live-forever-t-shirt-black-text"
      colorAndSize="5 colors, 8 sizes"
    />
    <one-shirt
      imageFileName="live_forever_white_text"
      altText="I'm Gonna Live Forever, White Text"
      shirtName="I'm Gonna Live Forever (White Text)"
      shirtUrl="https://autosaved-store.printify.me/product/3078430/im-gonna-live-forever-t-shirt-white-text"
      colorAndSize="8 colors, 8 sizes"
    />
    <one-shirt
      imageFileName="autosaved_shirt"
      altText="Autosaved Logo Shirt"
      shirtName="Autosaved Logo Shirt"
      shirtUrl="https://autosaved-store.printify.me/product/3078289/autosaved-logo-t-shirt"
      colorAndSize="9 colors, 8 sizes"
    />
  </div>
</template>

<script>
	import OneShirt from "../components/Shirts/OneShirt.vue";

	export default {
		name: 'FactoryPlanner',
		components: {
			'one-shirt': OneShirt,
		},
	}
</script>

<style lang="scss">

.textDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.titleSpan{
  font-weight: bold;
}

.colorSpan {
  color: #666666;
}

.priceSpan {
  color: #666666;
}

</style>